.wrapper {
  flex-grow: 1;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}
.container {
  width: 80%;
  max-width: 1500px;
  display: flex;
  justify-content: center;
}
.header {
  position: fixed;
  width: 100%;
  background: #fff;
}
.title {
  color: var(--primaryColor);
  font-size: 42px;
  line-height: 54px;
  font-family: var(--semiBold);
  margin-bottom: 50px;
}
.subTitle {
  font-family: var(--medium);
  color: var(--greyColor);
  font-size: 18px;
}
.logo {
  margin-right: 10px;
}
.flex {
  display: flex;
}
.align-center {
  align-items: center;
}
.justify-center {
  justify-content: center;
}
.column {
  display: flex;
  flex-direction: column;
}
.yatraIcon {
  width: 135px;
  height: 135px;
}
.yatraIconContainer {
  background-color: rgb(250, 227, 233);
  border-radius: 150px;
  padding: 10px;
}

.leftTemplateStyle {
  grid-area: leftTemple;
}
.centerTemplateStyle {
  grid-area: centerTemple;
}
.rightTemplateStyle {
  grid-area: rightTemple;
  text-align: right;
}
.gridContainer {
  display: grid;
  grid-template-areas: "leftTemple centerTemple rightTemple";
}

.footerContainer {
  display: grid;
  width: 100%;
  grid-template-areas: "leftFooter centerFooter rightFooter";
}

.footerLeftItem {
  grid-area: leftFooter;
  padding: 10px;
  text-align: center;
}
.footerCenterItem {
  grid-area: centerFooter;
}
.footerRightItem {
  grid-area: rightFooter;
  padding: 10px;
}
.privacySection {
  display: flex;
  align-items: center;
  padding: 30px 30px;
  border-left: 0.2px solid rgba(85, 85, 85, 1);
  border-right: 0.2px solid rgba(85, 85, 85, 1);
}
@media only screen and (max-width: 800px) {
  .gridContainer {
    grid-template-areas: "leftTemple   rightTemple " "centerTemple centerTemple";
  }
}
@media only screen and (min-width: 1800px) {
  .title {
    font-size: 65px;
    line-height: 75px;
    margin-bottom: 50px;
  }
  .subTitle {
    font-size: 25px;
    line-height: 35px;
  }
}
@media only screen and (max-width: 1200px) {
  .title {
    font-size: 35px;
    line-height: 40px;
    margin-bottom: 30px;
  }
  .subTitle {
    font-size: 16px;
    line-height: 25px;
  }
}
@media only screen and (max-width: 1000px) {
  .title {
    font-size: 30px;
    line-height: 35px;
    margin-bottom: 30px;
  }
  .subTitle {
    font-size: 16px;
    line-height: 25px;
  }
}

@media only screen and (max-width: 600px) {
  .footerContainer {
    grid-template-areas: "rightFooter" "centerFooter" "leftFooter ";
  }
  .privacySection {
    border: 0;
    padding: 30px 0px;
    border-top: 0.2px solid rgba(85, 85, 85, 1);
    border-bottom: 0.2px solid rgba(85, 85, 85, 1);
  }
  .footerLeftItem {
    padding: 30px;
  }
  /* .footerCenterItem {
  } */
  .footerRightItem {
    padding: 50px;
  }
}

.privacy-policy-title {
  width: 166px;
  height: 17px;
  top: 200px;
  left: 120px;
  font-family: var(--semiBold);
  font-size: 24px;
  font-weight: 600;
  line-height: 31px;
  letter-spacing: -0.01em;
  text-align: center;
  color: var(--primaryColor);
  position: absolute;
  z-index: 999;
}
.privacy-policy-content {
  width: 1200px;
  top: 60px;
  padding-bottom: 60px;
  left: 120px;
  font-family: var(--medium);
  font-size: 18px;
  font-weight: 500;
  line-height: 27px;
  letter-spacing: -0.01em;
  text-align: left;
  color: var(--greyColor);
  position: absolute;
  z-index: 999;
}

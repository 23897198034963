/* AppButton.css */
.appButton {
  background-color: #e4184c; /* The color shown in the image */
  color: white;
  border: none;
  border-radius: 20px; /* Assuming it's a pill-shaped button */
  padding: 8px 20px; /* Adjust the padding as needed */
  font-size: 16px; /* Adjust the font size as needed */
  cursor: pointer;
  outline: none;
  font-family: var(--font-medium); /* Use CSS variables for constants */
}

/* Define your font constant as a CSS variable in a global CSS file */
:root {
  --font-medium: "Poppins-Medium"; /* Replace with your FONT_CONSTANT.medium value */
}

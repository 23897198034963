body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

@font-face {
  font-family: 'Poppins-Bold';
  src: url('./assets/fonts/Poppins-Bold.ttf') format('truetype');
}

@font-face {
  font-family: 'Poppins-semiBold';
  src: url('./assets/fonts/Poppins-SemiBold.ttf') format('truetype');
}

@font-face {
  font-family: 'Poppins-Medium';
  src: url('./assets/fonts/Poppins-Medium.ttf') format('truetype');
}

@font-face {
  font-family: 'Poppins-Regular';
  src: url('./assets/fonts/Poppins-Regular.ttf') format('truetype');
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

:root {
  --regular: 'Poppins-Regular';
  --medium: 'Poppins-Medium';
  --semiBold: 'Poppins-semiBold';
  --bold: 'Poppins-Bold';
  /* color */
  --primaryColor:#E4174B;
  --greyColor:#525257
}